import React from 'react'
import { auth } from '../fireabase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import Navbar from '../components/navbar';
import DashboardModule from '../modules/dashboard';

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  if (loading) {
    return <div>Loading</div>
  }
  return (
    <div>
      <Navbar user={user} />
      <DashboardModule />
    </div>
  )

}

export default Dashboard