import { child, get, ref, remove, set, update } from "firebase/database";
import { database } from "../fireabase-config";

export async function addDocument({ userName, nodeName, data }) {
    try {
        const res = set(ref(database, `documents/${userName}/${nodeName}`), data);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function addDocumentSection({ userName, documentKey, nodeName, data }) {
    try {
        const res = set(ref(database, `documents/${userName}/${documentKey}/sections/${nodeName}`), data);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function addSectionArticle({ userName, documentKey, sectionKey, articleKey, data }) {
    try {
        const res = set(ref(database, `documents/${userName}/${documentKey}/sections/${sectionKey}/articles/${articleKey}`), data);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function updateSectionArticle({ userName, documentKey, sectionKey, articleKey, data }) {
    try {
        const res = update(ref(database, `documents/${userName}/${documentKey}/sections/${sectionKey}/articles/${articleKey}`), data);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function updateHeadings({ userName, data }) {
    try {
        const res = await update(ref(database, `userNames/${userName}`), data);
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function deletePath({ path }) {
    try {
        const res = await remove(ref(database, path));
        return 1;
    } catch (err) {
        console.log(err)
        return 0;
    }
}

export async function getAllArticlesFromDocument({ path }) {
    try {
        const res = await get(ref(database, path));
        const data = res.val().sections;
        const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
        const allArticles = dataArray.reduce((accumulator, currentSection) => {
            if (currentSection.articles) {
                const { key, articles, position } = currentSection;
                const sectionArticles = Object.entries(articles).map(([articleId, articleData]) => ({
                    ...articleData,
                    sectionKey: key,
                    sectionPosition: position,
                }));
                sectionArticles.sort((a, b) => a.position - b.position);
                return accumulator.concat(sectionArticles);
            }
            return accumulator;
        }, []);
        return allArticles;
    } catch (err) {
        console.log(err)
        return 0;
    }
}