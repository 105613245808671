import React from 'react'
import Logo from './logo'
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
} from "@material-tailwind/react";
import { signOut } from 'firebase/auth'
import { auth } from '../fireabase-config'
import { Link, useNavigate } from 'react-router-dom'

const Navbar = ({ user }) => {
    const navigate = useNavigate();
    const userName = localStorage.getItem("userName")
    return (
        <div className='w-full border-b py-6 flex items-center'>
            <div className='w-full flex items-center justify-between px-4 max-w-[1300px] m-auto'>
                <Logo />
                <div className='flex items-center gap-2 md:gap-5'>
                    {!user &&
                        <Button
                            onClick={() => {
                                navigate("/")
                            }}
                            variant='filled'
                            className='bg-[#0D182E]'
                        >
                            Login
                        </Button>
                    }
                    {user && (
                        <>
                            <Link to={`/${userName}`}><p>My Dlog</p></Link>
                            <Menu placement="bottom-end">
                                <MenuHandler>
                                    <p className='cursor-pointer'>{user.displayName}</p>
                                </MenuHandler>
                                <MenuList>
                                    <MenuItem
                                        onClick={() => {
                                            navigate("/");
                                            localStorage.removeItem("userName")
                                            signOut(auth);
                                        }}
                                    >
                                        Logout
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Navbar