import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { database } from '../fireabase-config';
import { Link } from 'react-router-dom';

const ArticlesList = ({ params, sectionKey, activeArticle }) => {
    const [articles, setArticles] = useState([]);
    const getArticles = async () => {
        onValue(ref(database, `documents/${params.username}/${params.docname}/sections/${sectionKey}/articles/`), (snapshot) => {
            if (snapshot.val() == null) return;
            const data = snapshot.val();
            const dataArray = Object.entries(data).map(([key, value]) => ({ ...value }));
            const sortedArray = dataArray.sort((a, b) => a.position - b.position);
            setArticles(sortedArray);
        })
    }
    useEffect(() => {
        getArticles();
    }, [])
    return (
        <div className='flex flex-col gap-2 mt-3'>
            {articles.length > 0 && (
                <>
                    {articles.map((item, index) => (
                        <Link to={`/${params.username}/${params.docname}/${sectionKey}/${item.key}`}>
                            <p
                                key={index}
                                className={`capitalize text-black md:text-white text-xs py-2 pl-4 ${item.key == activeArticle?.key ? "font-semibold text-[#28b76b] border-l-2 border-white" : "border-l border-transparent"}`}
                            >
                                {item.title}
                            </p>
                        </Link>
                    ))}
                </>
            )}
        </div>
    )
}

export default ArticlesList